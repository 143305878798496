<template>
    <b-row>
        <b-col cols="6">
            <zw-select-group v-model="inputVal.receiver"
                             :options="receiverOptions"
                             name="receiver"
                             :label-prefix="labelPrefix"
                             validate="required"
            ></zw-select-group>
        </b-col>
        <b-col cols="6" v-if="inputVal.receiver=='me'">
            <zw-select-group v-model="inputVal.user"
                             :options="getUsersList()"
                             name="user"
                             :label-prefix="labelPrefix"
                             validate="required"
                             text-field="fullname"
                             value-field="id"
            ></zw-select-group>
        </b-col>
        <b-col cols="6">
            <zw-select-group v-model="inputVal.template"
                             :options="getEmailTemplates()"
                             name="template"
                             :label-prefix="labelPrefix"
                             text-field="name"
                             value-field="id"
                             validate="required"
            ></zw-select-group>
        </b-col>
        <b-col cols="6">
            <multiselect
                v-model="inputVal.attachment"
                name="attachment"
                :options="Object.keys(getDocumentsList())"
                :multiple="true"
                :custom-label="opt => getDocumentsList()[opt]"
            >
            </multiselect>
        </b-col>
        <b-col cols="6">
            <zw-select-group v-model="inputVal.smtp"
                             :options="getUsersList()"
                             name="smtp"
                             :label-prefix="labelPrefix"
                             text-field="fullname"
                             value-field="id"
            ></zw-select-group>
        </b-col>
        <b-col cols="6">
            <zw-input-group v-model="inputVal.cc"
                            name="cc"
                            :label-prefix="labelPrefix"
            ></zw-input-group>
        </b-col>
        <b-col cols="6">
            <zw-input-group v-model="inputVal.bcc"
                            name="bcc"
                            :label-prefix="labelPrefix"
            ></zw-input-group>
        </b-col>
        <b-col cols="12">
            <zw-attachments v-model="inputVal.attachments" :parent_id="inputVal.id"/>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";
import ZwAttachments from "@/components/ZwAttachments";

export default {
    name: 'SendEmailOptions',
    components: {ZwAttachments},
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
            receiverOptions: {
                'me': this.$t('settings.workflow.receiver.me'),
                'customer': this.$t('settings.workflow.receiver.customer')
            },
        }
    },
    mounted() {
        if (typeof this.inputVal.attachments == 'undefined') {
            this.$set(this.inputVal, 'attachments', [])
        }
    },
    methods: {
        ...mapGetters('Users', ['getUsersList']),
        ...mapGetters('CommonData', ['getEmailTemplates', 'getDocuments']),
        getDocumentsList() {
            let result = {}
            result['kva'] = this.$t('invoices.document.kva')

            this.getDocuments().forEach(document => {
                result[document] = this.$t('invoices.document.' + document)
            })
            return result
        },
        addFile(file) {
            this.form.additional_attachments.push(file)
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>